<form [formGroup]="costsForm" class="space-top-3">
  <div class="row center">
    <div class="column">
      <fol-form-header type="HOLDER" id="holderFormHeader">
        <fol-currency-input formControlName="housingTax" *ngIf="hasHousingCosts"
                            [label]='"application.costs.fields.housing-tax" | translate'
                            [toggleValidationIcon]="true" id="field1"/>
      </fol-form-header>

      <div *ngIf="(isHousingTaxFulfilled$ | async)?.value">
        <div class="row center space-top-2">
          <h1>{{ 'application.costs.other.' + (!hasHousingCosts ? 'other-question' : 'question') | translate }}</h1>
        </div>
        <div class="row center text-center space-top-2 space-bot-2">{{ 'application.costs.other.sub-question' | translate }}</div>
        <fol-yes-no-input class="select" formControlName="hasOtherCosts" id="field2"/>

      </div>
      <ng-container formGroupName="otherTaxesGroup" *ngIf="costsForm.getRawValue().hasOtherCosts">
        <fol-currency-input formControlName="alimony" class="space-bot-2"
                            [label]='"application.costs.fields.alimony" | translate'
                            id="field3"/>
        <fol-currency-input formControlName="otherTaxes" class="space-bot-2"
                            [label]='"application.costs.fields.other-taxes" | translate'
                            id="field4"/>
        <fol-currency-input formControlName="automotiveCredit" class="space-bot-2"
                            [label]='"application.costs.fields.automotive-credit" | translate'
                            id="field5"/>
      </ng-container>
    </div>
  </div>
</form>
