import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ThemingService } from './core/theming/theming.service';
import { ThemingQueryParams } from './core/theming/theming.model';
import { TakeUntil } from './core/helpers/take-until.component';
import { filter, map, Observable, startWith, takeUntil } from 'rxjs';
import { StickyService } from './core/sticky.service';
import { VERSION_TOKEN } from './app.config';
import { EventType, NavigationEnd, Router } from '@angular/router';
import { BottomScrollService } from './core/helpers/bottom-scroll.service';

@Component({
  selector: 'fol-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent extends TakeUntil implements OnInit {
  @ViewChild('stickyContainer', { read: ViewContainerRef }) stickyContainer!: ViewContainerRef;
  partnerName = '';
  config = new Map<string, ThemingQueryParams>();
  step$: Observable<{ isOnSummaryPage: boolean }>;

  constructor(
    private readonly themingService: ThemingService,
    private readonly stickyService: StickyService,
    @Inject(VERSION_TOKEN) protected packageVersion: string,
    private readonly router: Router,
    private readonly bottomScrollService: BottomScrollService
  ) {
    super();

    this.step$ = router.events.pipe(filter(event => event.type === EventType.NavigationEnd)).pipe(
      map(navigationEnd => (navigationEnd as NavigationEnd).urlAfterRedirects === '/step'),
      startWith(false),
      map(isOnSummaryPage => {
        return { isOnSummaryPage };
      })
    );
  }

  ngOnInit(): void {
    this.themingService
      .getCurrentTheming()
      .pipe(takeUntil(this.destroy))
      .subscribe(theming => (this.partnerName = theming.brand));

    this.stickyService.stickyContent.pipe(takeUntil(this.destroy)).subscribe(value => {
      this.stickyContainer.clear();
      this.stickyContainer.createEmbeddedView(value);
    });

    this.stickyService.clear.pipe(takeUntil(this.destroy)).subscribe(() => this.stickyContainer.clear());

    this.config.set(
      'Honda',
      new ThemingQueryParams({
        unionCode: '7009316936'
      })
    );
    this.config.set(
      'Suzuki',
      new ThemingQueryParams({
        unionCode: '7009315227'
      })
    );
    this.config.set(
      'Jaguar',
      new ThemingQueryParams({
        unionCode: '7009317181'
      })
    );
    this.config.set(
      'Land Rover',
      new ThemingQueryParams({
        unionCode: '7009317199'
      })
    );
    this.config.set(
      'Volvo',
      new ThemingQueryParams({
        unionCode: '7009314717'
      })
    );
    this.config.set(
      'Qarson',
      new ThemingQueryParams({
        unionCode: '7009129933'
      })
    );
    this.config.set(
      'Cetelem',
      new ThemingQueryParams({
        societyCode: '700'
      })
    );
    this.config.set(
      'Amplitude CARSTORE',
      new ThemingQueryParams({
        unionCode: '7009125832'
      })
    );
  }

  onClick(brand: string): void {
    const config = this.config.get(brand);
    if (config) {
      this.themingService.getThemingJson(config).pipe(takeUntil(this.destroy)).subscribe();
    }
  }

  onScroll(event: Event): void {
    const target = event.target as HTMLElement;
    if (target.offsetHeight + target.scrollTop >= target.scrollHeight - 1) {
      this.bottomScrollService.launchTriggerBottom();
    }
  }
}
