import { AbstractControl } from '@angular/forms';

export type SinceType = {
  since: MonthYear | null;
};

export type MonthYear = {
  month: string;
  year: string;
};

export type ControlInput = {
  control: AbstractControl | null;
  input: HTMLInputElement | null;
};

export const isMonthYear = (x: unknown): x is MonthYear => {
  return (
    !!x && typeof x === 'object' && 'month' in x && 'year' in x && Object.keys(x).length === 2 && !!x.month && !!x.year
  );
};
