export enum Civility {
  MADAM = 'MADAM',
  MISTER = 'MISTER'
}

export enum FamilySituation {
  SINGLE = 'SINGLE',
  MARRIED = 'MARRIED',
  CONJUGAL = 'CONJUGAL',
  DIVORCED = 'DIVORCED',
  WIDOWED = 'WIDOWED'
}

export type Identity = {
  holder: PersonalData;
  coHolder: PersonalDataCoHolder;
};

export type PersonalData = {
  civility: Civility;
  firstName: string;
  lastName: string;
  birthName: string;
  mail: string;
  mobile: string;
  familySituation: FamilySituation;
};

export type PersonalDataCoHolder = Pick<PersonalData, 'civility' | 'firstName' | 'lastName' | 'mail' | 'mobile'>;

export enum FormState {
  HOLDER = 'HOLDER',
  CO_HOLDER = 'CO_HOLDER'
}

export function isLivingAlone(situation: FamilySituation): boolean {
  return (
    situation === FamilySituation.DIVORCED ||
    situation === FamilySituation.SINGLE ||
    situation === FamilySituation.WIDOWED
  );
}
