<ng-container *ngIf='control' >
  <div class='item' >
    <label [ngClass]='{
      "mat-mdc-form-field-error": control.invalid && control.touched,
      "required": required
    }' [for]="id">
      {{label}}
    </label>
  </div>
  <div class='item currency-input'>
    <mat-form-field income>
      <input type='text' matInput [formControl]='control'
             [maxlength]="30" [folControlRegex]="controlRegex" [id]="id" (focusout)="formatCurrency()" (focusin)="reverseFormatCurrency()"
             placeholder="0">
      <ng-content></ng-content>
      <span matTextSuffix>{{ unitTranslationKey | translate}}</span>
      <mat-icon matSuffix color="accent" *ngIf="controlBlurred && control.valid">check_circle</mat-icon>
      <mat-error *ngIf="control | getFirst as error">{{ error.key | translate: error.parameters }}</mat-error>
    </mat-form-field>
  </div>
</ng-container>
