const anyOf = (...regs: RegExp[]): RegExp => {
  const sources = regs.map(reg => (reg.source[0] === '[' ? reg.source : `[${reg.source}]`));
  return new RegExp(sources.join('').replace(/]\[/g, ''));
};
export const LOWERCASE = /[a-z]/;
export const UPPERCASE = /[A-Z]/;
export const NUMBER = /[0-9]/;
export const NON_ZERO_NUMBER = /[1-9]/;
export const SPECIAL_CHAR = /[^0-9a-zA-Z]/;
export const ALPHANUM = anyOf(UPPERCASE, NUMBER, LOWERCASE);
export const CHARACTERS = anyOf(UPPERCASE, LOWERCASE);
export const ADDRESS_SPECIAL_CHAR = /[' ]/;
export const ADDRESS_CHARS = anyOf(UPPERCASE, NUMBER, LOWERCASE, ADDRESS_SPECIAL_CHAR);

const DASH = /-/;
const MAIL_SPECIAL_CHAR = anyOf(DASH, /[!#$%&'*+/=?^_`{|}~]/);
export const EMAIL_REGEX = new RegExp(
  `^(${anyOf(ALPHANUM, MAIL_SPECIAL_CHAR).source}+(\\.${anyOf(ALPHANUM, MAIL_SPECIAL_CHAR).source}*)*)` +
    `@(${ALPHANUM.source}(${anyOf(ALPHANUM, DASH).source}*${ALPHANUM.source})*)` +
    `\\.(${ALPHANUM.source}${anyOf(ALPHANUM, DASH).source}{0,2}${ALPHANUM.source})$`
);
export const EMAIL_REGEX_INPUT = new RegExp(
  `^(${anyOf(ALPHANUM, MAIL_SPECIAL_CHAR).source}+(\\.${anyOf(ALPHANUM, MAIL_SPECIAL_CHAR).source}*)*)@?$` +
    `|` +
    `^(${anyOf(ALPHANUM, MAIL_SPECIAL_CHAR).source}+(\\.${anyOf(ALPHANUM, MAIL_SPECIAL_CHAR).source}*)*)` +
    `@(${ALPHANUM.source}(${anyOf(ALPHANUM, DASH).source}*${ALPHANUM.source})*)\\.?${ALPHANUM.source}?$` +
    `|` +
    `^(${anyOf(ALPHANUM, MAIL_SPECIAL_CHAR).source}+(\\.${anyOf(ALPHANUM, MAIL_SPECIAL_CHAR).source}*)*)` +
    `@(${ALPHANUM.source}(${anyOf(ALPHANUM, DASH).source}*${ALPHANUM.source})*)` +
    `\\.(${ALPHANUM.source}${anyOf(ALPHANUM, DASH).source}{0,2}${ALPHANUM.source})$`
);
export const TEXT_REGEX = new RegExp(`^${CHARACTERS.source}(${anyOf(CHARACTERS, /[' ]/).source})*$`);
export const NOT_BEGIN_PHONE = new RegExp(/^[^0]/);
export const MOBILE_REGEX = new RegExp(`^0[67]([-. ]?${NUMBER.source}{2}){4}$`);
export const MOBILE_REGEX_INPUT = new RegExp(`^0$|^0[67]([-. ]?${NUMBER.source}{0,2}){0,4}$`);
export const LANDLINE_PHONE_REGEX = new RegExp(`^0[1-589]([-. ]?${NUMBER.source}{2}){4}$`);
export const LANDLINE_PHONE_REGEX_INPUT = new RegExp(`^0$|^0[1-589]([-. ]?${NUMBER.source}{0,2}){0,4}$`);
export const PHONE_REGEX = new RegExp(`^0${NON_ZERO_NUMBER.source}([-. ]?${NUMBER.source}{2}){4}$`);
export const PHONE_REGEX_INPUT = new RegExp(`^0$|^0${NON_ZERO_NUMBER.source}([-. ]?${NUMBER.source}{0,2}){0,4}$`);
export const NUMBER_REGEX = new RegExp(`^${NUMBER.source}*$`);
export const INCOME_REGEX_INPUT = new RegExp(
  `^0$|(^${NON_ZERO_NUMBER.source}${NUMBER.source}{0,2}(${NUMBER.source}{0,3})*(${NUMBER.source}{0,2}){0,1}$)`
);
export const INCOME_REGEX = new RegExp(`^0$|(^${NON_ZERO_NUMBER.source}${NUMBER.source}*$)`);
export const EMPLOYEE_REGEX = new RegExp(`^${NUMBER.source}*$`);
export const BIC_REGEX_INPUT = new RegExp(`^${ALPHANUM.source}{0,11}$`);
export const BIC_REGEX = new RegExp(`^${ALPHANUM.source}{8,11}$`);
export const ADDRESS_REGEX = new RegExp(`^${ALPHANUM.source}${ADDRESS_CHARS.source}*$`);
export const MONTH_REGEX_INPUT = new RegExp(`^((0${NON_ZERO_NUMBER.source}?)|(1[0-2]?))$`);
export const YEAR_REGEX_INPUT = new RegExp(`^${NUMBER.source}{0,4}$`);
export const MONTH_REGEX = new RegExp(`^((0${NON_ZERO_NUMBER.source})|(1[0-2]))`);
export const YEAR_REGEX = new RegExp(`^${NUMBER.source}{4}$`);
export const DAY_REGEX = new RegExp(`^((0${NON_ZERO_NUMBER.source})|([12]${NUMBER.source})|(3[01]))`);
export const DAY_REGEX_INPUT = new RegExp(`^((0${NON_ZERO_NUMBER.source}?)|([12]${NUMBER.source}?)|(3[01]?))$`);
export const SIREN_REGEX = new RegExp(`^(${NUMBER.source}{3} ?){2}${NUMBER.source}{3}$`);
export const SIREN_REGEX_INPUT = new RegExp(`^(${NUMBER.source}{0,3}?){0,2}${NUMBER.source}{0,3}$`);
export const NAF_CODE_REGEX = new RegExp(`^${NUMBER.source}{4}${CHARACTERS.source}$`);
export const NAF_CODE_REGEX_INPUT = new RegExp(
  `^${NUMBER.source}{0,4}$|^${NUMBER.source}{4}${CHARACTERS.source}{0,1}$`
);
export const CAPITAL_REGEX_INPUT = new RegExp(`^${NON_ZERO_NUMBER.source}${NUMBER.source}{0,12}$`);
export const CAPITAL_REGEX = new RegExp(`^${NON_ZERO_NUMBER.source}${NUMBER.source}{1,11}$`);
export const IBAN_REGEX = new RegExp(
  `^(${CHARACTERS.source}{2}${NUMBER.source}{2}(${ALPHANUM.source}{4})*${ALPHANUM.source}{1,4})$`
);
export const IBAN_REGEX_INPUT = new RegExp(
  `^(${CHARACTERS.source}{0,2}|${CHARACTERS.source}{2}${NUMBER.source}{0,2}|${CHARACTERS.source}{2}${NUMBER.source}{2} ?(${ALPHANUM.source}{0,4} ?)* ?)$`
);
