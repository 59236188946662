export const toClassName = (str: string): string => str.toLowerCase().replace('_', '-');
export const capitalize = (str: string): string => `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
export const toCamelCase = ((): ((str: string) => string) => {
  const SPLIT_REGEX = /[-_]/;
  return (str: string): string =>
    str
      .split(SPLIT_REGEX)
      .map((s, i) => (i === 0 ? s.toLowerCase() : capitalize(s.toLowerCase())))
      .join('');
})();
