<ng-container *ngIf='control'>
  <div class='item'>
    <label [ngClass]='{
      "mat-mdc-form-field-error": control.invalid && control.touched,
      "required": required
    }' [for]="id">
      {{label}}
    </label>
  </div>
  <div class='item'>
    <mat-form-field [hintLabel]="hint">
      <input *ngIf="matAutocomplete" [type]='type' matInput [formControl]='control' [autocomplete]='autocomplete'
             [maxlength]="maxlength" [id]="id"
             [placeholder]="placeholder" [matAutocomplete]="matAutocomplete"/>
      <input *ngIf="!matAutocomplete" [type]='type' matInput [formControl]='control' [autocomplete]='autocomplete'
             [maxlength]="maxlength" [id]="id"
             [placeholder]="placeholder"/>
      <ng-content></ng-content>
      <mat-icon matSuffix color="accent" *ngIf="toggleValidationIcon && controlBlurred && control.valid">check_circle</mat-icon>
      <mat-error *ngIf="control | getFirst as error">{{ error.key | translate: error.parameters }}</mat-error>
    </mat-form-field>
  </div>
</ng-container>
