import { AfterViewInit, ChangeDetectionStrategy, Component, TemplateRef, ViewChild } from '@angular/core';
import { Step } from './stepper.model';
import { StepperService } from './stepper.service';
import { iif, map, mergeMap, Observable, of, tap } from 'rxjs';
import { StickyService } from '../sticky.service';
import { DataTransmissionService } from '../application/data-transmission/data-transmission.service';
import { DecisionEnum } from '../application/data-transmission/data-transmission.model';

type StepForFront = { lowercase: string; enumValue: Step };

@Component({
  selector: 'fol-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StepperComponent implements AfterViewInit {
  @ViewChild('stickyButtonWrapper', { read: TemplateRef }) stickyButtonWrapper!: TemplateRef<HTMLElement>;
  isApplicationState = false;
  isSignatureState = false;
  isSupportingDocumentsState = false;
  isFinalState = false;

  RED_LIGHT = DecisionEnum.RED;
  state$: Observable<Step> = this.stepperService.activeStep.pipe(
    map(workflow => {
      const activeStep = workflow.activeStep;
      this.isApplicationState = activeStep === Step.APPLICATION;
      this.isSignatureState = activeStep === Step.SIGNATURE;
      this.isSupportingDocumentsState = activeStep === Step.SUPPORTING_DOCUMENTS;
      this.isFinalState = activeStep === Step.FINAL;
      return activeStep;
    })
  );
  steps: StepForFront[] = Object.keys(Step)
    .filter(stepName => stepName !== 'FINAL')
    .map(s => ({ lowercase: s.toLowerCase(), enumValue: Step[s as keyof typeof Step] }));
  validatedSteps = this.stepperService.getValidatedSteps();

  dataTransmission$ = this.state$.pipe(
    mergeMap(() => {
      return iif(
        () => this.isSupportingDocumentsState,
        this.dataTransmissionService.sendData().pipe(
          map(retour => retour.decision.toLowerCase()),
          tap(() => {
            // Waiting for the stepper to be visible after the completion of the calculus
            this.stickyService.setStickyContent(this.stickyButtonWrapper);
          })
        ),
        of(null)
      );
    })
  );

  constructor(
    private readonly stepperService: StepperService,
    private readonly stickyService: StickyService,
    private readonly dataTransmissionService: DataTransmissionService
  ) {}

  ngAfterViewInit(): void {
    if (!this.isSupportingDocumentsState) {
      this.stickyService.setStickyContent(this.stickyButtonWrapper);
    } else {
      this.stickyService.clearStickyContent();
    }
  }
}
