import { KeyValue } from '../core.model';

type EnumLike = {
  [id: string]: string;
};

export const toKeyValue = (
  myEnum: EnumLike,
  transformKey: (_: string) => string = (a: string): string => a
): KeyValue[] => Object.entries(myEnum).map(([key, value]) => ({ key: transformKey(key), value: String(value) }));

export const getValue = (keyValue: KeyValue): string => keyValue.value;
