import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TakeUntil } from '../../helpers/take-until.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ensureBooleanOrNull } from '../../helpers/boolean.helper';
import { combineLatest, debounceTime, map, mergeMap, Observable, of, startWith, takeUntil } from 'rxjs';
import { isValueLessThanTwoChars } from '../../helpers/object.helper';
import {
  getDebounceTimeIfIsRecap,
  removeControlIfPresent,
  ToForm,
  toGroupValidator
} from '../../helpers/form/form.helper';
import { ApplicationStore } from '../application-store.service';
import { Housing } from '../housing-form/housing-form.model';
import { Costs, CostsFormData } from './costs.component.model';

export type OtherTaxesGroup = Pick<Costs, 'alimony' | 'otherTaxes' | 'automotiveCredit'>;
type HousingTaxesField = ToForm<Pick<Costs, 'housingTax'>>;
type CostsForm = ToForm<Pick<Costs, 'hasOtherCosts'>> & {
  otherTaxesGroup?: FormGroup<ToForm<OtherTaxesGroup>>;
} & Partial<HousingTaxesField>;

@Component({
  selector: 'fol-costs-form',
  templateUrl: './costs-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CostsFormComponent extends TakeUntil implements OnInit {
  @Output() formFulfilled = new EventEmitter<CostsFormData | null>();
  @Input() isRecap = false;
  hasHousingCosts = false;
  hasPreFilled = false;
  costsForm: FormGroup<CostsForm> = new FormGroup<CostsForm>({
    hasOtherCosts: new FormControl<boolean | null>(
      ensureBooleanOrNull(this.applicationStore.instant('costs')?.hasOtherCosts),
      Validators.required
    )
  });
  computeFormState$ = new EventEmitter<void>();
  isHousingTaxFulfilled$: Observable<{ value: boolean }> = this.computeFormState$.pipe(
    startWith(null),
    mergeMap(() =>
      combineLatest({
        housingInput: this.costsForm.controls.housingTax
          ? this.costsForm.controls.housingTax.valueChanges.pipe(startWith(this.costsForm.controls.housingTax.value))
          : of(null),
        hasOtherCosts: this.costsForm.controls.hasOtherCosts.valueChanges.pipe(
          startWith(this.costsForm.controls.hasOtherCosts.value || false)
        )
      })
    ),
    map(({ housingInput, hasOtherCosts }) => {
      if (!this.hasHousingCosts || !isValueLessThanTwoChars(housingInput)) {
        if (hasOtherCosts) {
          this.addOtherTaxesControl();
        } else {
          removeControlIfPresent(this.costsForm, 'otherTaxesGroup');
        }
        return { value: true };
      } else {
        this.costsForm.get('hasOtherCosts')?.setValue(null, { emitEvent: false });
        return { value: false };
      }
    })
  );

  @Input() set toggleForm(formToggled: boolean) {
    formToggled ? this.costsForm.enable() : this.costsForm.disable();
  }

  constructor(private readonly applicationStore: ApplicationStore) {
    super();
  }

  ngOnInit(): void {
    const holderHousing = this.applicationStore.instant('housingData')?.housing;
    this.hasHousingCosts = !!(holderHousing && [Housing.OWNER_WITH_LOAN, Housing.TENANT].includes(holderHousing));
    if (this.hasHousingCosts && !this.costsForm.contains('housingTax')) {
      this.costsForm.addControl(
        'housingTax',
        new FormControl<string>(this.applicationStore.instant('costs')?.housingTax || '', Validators.required)
      );
    }
    this.computeFormState$.emit();
    this.costsForm.statusChanges
      .pipe(takeUntil(this.destroy), debounceTime(getDebounceTimeIfIsRecap(this.isRecap)))
      .subscribe(() => {
        this.formFulfilled.emit(this.costsForm.valid ? (this.costsForm.getRawValue() as CostsFormData) : null);
      });
  }

  private addOtherTaxesControl(): void {
    if (!this.costsForm.contains('otherTaxesGroup')) {
      this.costsForm.addControl(
        'otherTaxesGroup',
        new FormGroup<ToForm<OtherTaxesGroup>>(
          {
            alimony: new FormControl<string>(this.applicationStore.instant('costs')?.alimony || ''),
            otherTaxes: new FormControl<string>(this.applicationStore.instant('costs')?.otherTaxes || ''),
            automotiveCredit: new FormControl<string>(this.applicationStore.instant('costs')?.automotiveCredit || '')
          },
          {
            validators: toGroupValidator(otherTaxesGroup => {
              return this.atLeastOneTaxeAboveZero(otherTaxesGroup.getRawValue());
            }, 'personal-incomes.one-field')
          }
        )
      );
      if (this.isRecap && !this.hasPreFilled) {
        this.costsForm.controls.otherTaxesGroup?.disable();
        this.hasPreFilled = true;
      }
    }
  }

  private atLeastOneTaxeAboveZero(otherTaxesGroup: OtherTaxesGroup): boolean {
    return !!(
      (otherTaxesGroup.alimony && otherTaxesGroup.alimony !== '0') ||
      (otherTaxesGroup.automotiveCredit && otherTaxesGroup.automotiveCredit !== '0') ||
      (otherTaxesGroup.otherTaxes && otherTaxesGroup.otherTaxes !== '0')
    );
  }
}
