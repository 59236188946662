import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../../app.config';
import { urlJoin } from '../../helpers/url.helper';
import { Observable, of } from 'rxjs';
import { Duration, Summary } from './summary.model';

export const mockedSummary = {
  financedAmount: '13080',
  firstRent: '3500',
  nextRents: '383,83',
  duration: Duration.TWENTY_FOUR,
  purchaseOption: '7500',
  aggregateAmount: '22800',
  annualKm: '15000'
};

@Injectable({
  providedIn: 'root'
})
export class SummaryService {
  url = '';
  constructor(private readonly httpClient: HttpClient, private readonly appConfig: AppConfig) {
    this.url = urlJoin(appConfig.baseWsURL(), '');
  }

  getSummaryData(): Observable<Summary> {
    return of(mockedSummary);
  }
}
