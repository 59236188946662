import { MonthYear, SinceType } from '../../../graphic/atom/form-fields/month-year-input/month-year-model';
import { KeyValue } from '../../core.model';
import { Locality } from '../birth-information/localities.service';
import { ActivityType } from '../employment-situation-form/liberal-profession-form/liberal-profession-form.model';

export enum PremisesAndBusiness {
  OWNER = 'P',
  TENANT = 'L'
}

export type NamePremisesAndEmployeeNumberType = {
  companyName: string;
  premises: PremisesAndBusiness;
  employeeNumber: string;
};

export type HolderOnlyCompanyInformation = Omit<HolderCompanyInformation, keyof NamePremisesAndEmployeeNumberType>;

export type HolderCompanyInformation = {
  legalStatus: KeyValue | null;
  creationDate: MonthYear | null;
  transplantCity: string;
  nafCode: string;
  capital: number | string;
  businessAssets: PremisesAndBusiness | null;
} & ActivityType &
  NamePremisesAndEmployeeNumberType &
  SinceType;

export type CoHolderCompanyInformation = NamePremisesAndEmployeeNumberType;

export type CompanyContactDetailsData = {
  address: string;
  additionalAddress: string;
  zipCode: string | number | Locality;
  city: string;
  phone: string;
};

export type CompanyInformationData<T> = {
  sirenNumber?: string;
  information?: T;
  contactDetails?: CompanyContactDetailsData;
};

export type CompanyInformation = {
  holder: CompanyInformationData<HolderCompanyInformation>;
  coHolder: CompanyInformationData<CoHolderCompanyInformation>;
};

export type ManagerQueryParam = {
  manager: string;
};
