import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Inject,
  LOCALE_ID,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SummaryService } from './summary.service';
import { combineLatest, map, Observable, startWith, tap } from 'rxjs';
import { Duration, Summary, SummaryModification } from './summary.model';
import { KeyValue } from '../../core.model';
import { toKeyValue } from '../../helpers/enum.helpers';
import { FormControl, FormGroup } from '@angular/forms';
import { ToForm } from '../../helpers/form/form.helper';
import { INCOME_REGEX_INPUT } from '../../helpers/regexp.helper';
import { formatNumber } from '@angular/common';
import { StickyService } from '../../sticky.service';

@Component({
  selector: 'fol-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SummaryComponent implements OnInit, AfterViewInit {
  @ViewChild('stickyButtonWrapper', { read: TemplateRef }) stickyButtonWrapper!: TemplateRef<HTMLElement>;
  isFormShowed = false;
  FIELD_REGEX = INCOME_REGEX_INPUT;
  annualKmSelection: KeyValue[] = [
    { key: '10000', value: '' },
    { key: '15000', value: '' },
    { key: '20000', value: '' },
    { key: '25000', value: '' }
  ];
  spacing: string = this.translateService.instant('application.summary.summary-fields.space');

  translatedDatesMap$: Observable<KeyValue[]> = this.translateService.onTranslationChange.pipe(
    startWith(''),
    map(() =>
      toKeyValue(Duration, key => this.translateService.instant(`application.summary.durations.${key.toLowerCase()}`))
    )
  );

  summaryForm = new FormGroup<ToForm<SummaryModification>>({
    duration: new FormControl<Duration | null>(null),
    annualKm: new FormControl<KeyValue | null>(null),
    financedAmount: new FormControl<string>('')
  });

  summaryData$: Observable<Summary> = combineLatest([
    this.summaryForm.valueChanges,
    this.summaryService.getSummaryData().pipe(
      tap(data =>
        this.summaryForm.patchValue({
          duration: data.duration,
          annualKm: this.annualKmSelection.find(keyValue => keyValue.key === data.annualKm),
          financedAmount: data.financedAmount
        })
      )
    )
  ]).pipe(
    map(([formData, serviceData]) => {
      if (formData.annualKm && typeof formData.annualKm === 'object') {
        formData.annualKm = formData.annualKm.key;
      }
      return { ...serviceData, ...formData } as Summary;
    })
  );

  constructor(
    private readonly translateService: TranslateService,
    private readonly summaryService: SummaryService,
    private readonly stickyService: StickyService,
    @Inject(LOCALE_ID) private readonly locale: string
  ) {}

  ngOnInit(): void {
    this.annualKmSelection.forEach(keyValue => (keyValue.value = formatNumber(Number(keyValue.key), this.locale)));
  }

  ngAfterViewInit(): void {
    this.stickyService.setStickyContent(this.stickyButtonWrapper);
  }

  toggleForm(): void {
    this.isFormShowed = !this.isFormShowed;
  }
}
