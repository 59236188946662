import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DoCheck,
  Input,
  OnDestroy,
  Optional,
  Self
} from '@angular/core';
import { ErrorStateMatcher, mixinErrorState } from '@angular/material/core';
import { AbstractMatFormField } from '../form-fields.model';
import { ControlValueAccessor, FormControl, FormGroupDirective, NgControl, NgForm, Validators } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { KeyValue } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'fol-yes-no-input',
  templateUrl: './yes-no-input.component.html',
  styleUrls: ['../radio-select/radio-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class YesNoInputComponent
  extends mixinErrorState(AbstractMatFormField)
  implements ControlValueAccessor, AfterContentInit, DoCheck, OnDestroy
{
  @Input() label = '';
  translatedYesOrNo: KeyValue<string, boolean>[] = [
    { key: this.translateService.instant('application.personal-incomes.yes-or-no.yes'), value: true },
    { key: this.translateService.instant('application.personal-incomes.yes-or-no.no'), value: false }
  ];
  control: FormControl | null = null;
  onChange?: (value: string) => void;
  onTouched?: () => void;
  required = false;
  subscription: Subscription | undefined;

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    public defaultErrorStateMatcher: ErrorStateMatcher,
    @Optional() public parentForm: NgForm,
    @Optional() public parentFormGroup: FormGroupDirective,
    private readonly cdr: ChangeDetectorRef,
    private readonly translateService: TranslateService
  ) {
    super(defaultErrorStateMatcher, parentForm, parentFormGroup, ngControl, new Subject<void>());
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    } else {
      throw new Error("You must set a formControl for all 'fol-yes-no-input'");
    }
  }

  ngDoCheck(): void {
    this.updateErrorState();
  }

  registerOnChange(fn: (_: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(obj: string): void {
    if (this.control?.value !== obj) {
      this.control?.setValue(obj);
    }
  }

  ngAfterContentInit(): void {
    if (this.ngControl.control) {
      this.control = this.ngControl.control as FormControl;
      this.required = this.control.hasValidator(Validators.required);

      this.subscription = this.control.statusChanges.subscribe(() => {
        this.cdr.markForCheck();
      });
    }
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
