import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl } from '@angular/forms';

export type ErrorForTransform = {
  key: string;
  parameters: {
    [id: string]: string;
  };
};

@Pipe({
  name: 'getFirst',
  pure: false
})
export class GetFirstPipe implements PipeTransform {
  transform(control: AbstractControl | null): ErrorForTransform | null {
    if (!control || control.errors === null) {
      return null;
    } else {
      const keys = Object.keys(control.errors);
      const firstError =
        keys.find(key => key.includes('priority.primary')) ||
        keys.find(key => key.includes('priority.secondary.')) ||
        keys[0];

      if (control.errors[firstError] === true) {
        return { key: `errors.${firstError}`, parameters: {} };
      } else {
        return { key: `errors.${firstError}`, parameters: control.errors[firstError] };
      }
    }
  }
}
