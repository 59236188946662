import { EventEmitter, Injectable, TemplateRef } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StickyService {
  stickyContent = new Subject<TemplateRef<HTMLElement>>();
  clear = new EventEmitter<void>();

  setStickyContent(template: TemplateRef<HTMLElement>): void {
    this.stickyContent.next(template);
  }

  clearStickyContent(): void {
    this.clear.emit();
  }
}
