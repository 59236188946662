import { Injectable } from '@angular/core';
import { urlJoin } from '../../helpers/url.helper';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../../app.config';
import { catchError, map, Observable, of } from 'rxjs';
import { DecisionEnum, Score } from './data-transmission.model';
import { ApplicationStore } from '../application-store.service';
import { getSubscriptionScoringDataFromApplicationData } from '../../helpers/data-transmission.mapper';

@Injectable({
  providedIn: 'root'
})
export class DataTransmissionService {
  private readonly url = urlJoin(this.appConfig.baseWsURL());
  constructor(
    private readonly httpClient: HttpClient,
    private readonly appConfig: AppConfig,
    private readonly applicationStore: ApplicationStore
  ) {}

  sendData(): Observable<Score> {
    const applicationData = getSubscriptionScoringDataFromApplicationData(this.applicationStore.value);
    return this.httpClient.post(`${this.url}/subscribe`, applicationData, { observe: 'response' }).pipe(
      map(decision => decision.body as Score),
      catchError(() => of({ decision: DecisionEnum.RED }))
    );
  }
}
