export enum Step {
  APPLICATION = 'APPLICATION',
  SUPPORTING_DOCUMENTS = 'SUPPORTING_DOCUMENTS',
  SIGNATURE = 'SIGNATURE',
  FINAL = 'FINAL'
}

export type WorkflowStep = { activeStep: Step; nextStep: WorkflowStep | null };
const stepFinal: WorkflowStep = { activeStep: Step.FINAL, nextStep: null };
const stepSignature: WorkflowStep = { activeStep: Step.SIGNATURE, nextStep: stepFinal };
const stepSupportingDocuments: WorkflowStep = { activeStep: Step.SUPPORTING_DOCUMENTS, nextStep: stepSignature };
const stepApplication: WorkflowStep = { activeStep: Step.APPLICATION, nextStep: stepSupportingDocuments };

export const INITIAL_STEP = stepApplication;
export const SUPPORTING_DOCUMENT_STEP = stepSupportingDocuments;
