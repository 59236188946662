<div class="breadcrumb" *ngIf="isBreadcrumbShown">
  <base-icon icon="people" [ngClass]="{ 'theming':  breadcrumbAdvancementName ==='people' }"></base-icon>
  <base-icon icon="dash"></base-icon>
  <mat-icon svgIcon='fol-biberon' [ngClass]="{'theming': breadcrumbAdvancementName === 'fol-biberon' }"></mat-icon>
  <base-icon icon="dash"></base-icon>
  <mat-icon svgIcon='fol-localisation' [ngClass]="{'theming': breadcrumbAdvancementName === 'fol-localisation' }"></mat-icon>
  <base-icon icon="dash"></base-icon>
  <base-icon icon="familly" [ngClass]="{'theming': breadcrumbAdvancementName === 'familly' }"></base-icon>
  <base-icon icon="dash"></base-icon>
  <base-icon icon="work" [ngClass]="{'theming': breadcrumbAdvancementName === 'work' }"></base-icon>
  <base-icon icon="dash"></base-icon>
  <mat-icon svgIcon='fol-tirelire' [ngClass]="{'theming': breadcrumbAdvancementName === 'fol-tirelire' }"></mat-icon>
  <base-icon icon="dash"></base-icon>
  <base-icon icon="wallet" [ngClass]="{'theming': breadcrumbAdvancementName === 'wallet' }"></base-icon>
  <base-icon icon="dash"></base-icon>
  <base-icon icon="bank" [ngClass]="{'theming': breadcrumbAdvancementName === 'bank' }"></base-icon>
</div>
