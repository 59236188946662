import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  OnInit
} from '@angular/core';
import '@design-system/base-icon';
import { TakeUntil } from '../../../core/helpers/take-until.component';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs';
import { NgClass, NgIf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { ActivationStart, Router } from '@angular/router';

@Component({
  selector: 'fol-breadcrumb-trail',
  templateUrl: './breadcrumb-trail.component.html',
  styleUrls: ['./breadcrumb-trail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BreadcrumbTrailComponent extends TakeUntil implements OnInit {
  isBreadcrumbShown = false;
  breadcrumbAdvancementName = '';
  constructor(private readonly route: Router, private readonly cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.route.events
      .pipe(
        filter(event => event instanceof ActivationStart),
        distinctUntilChanged(),
        takeUntil(this.destroy)
      )
      .subscribe(event => {
        if (event instanceof ActivationStart) {
          this.isBreadcrumbShown = !!event.snapshot.data['breadcrumb'];
          this.breadcrumbAdvancementName = event.snapshot.data['breadcrumb'];
          this.cdr.markForCheck();
        }
      });
  }
}

@NgModule({
  declarations: [BreadcrumbTrailComponent],
  exports: [BreadcrumbTrailComponent],
  imports: [NgClass, MatIconModule, NgIf],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class BreadCrumbTrailModule {}
