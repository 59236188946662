import { NoValue } from '../core.model';

const zeroX = /^0./;

export const someFieldIncludes = <T, F extends keyof T>(object: T, fields: F[], search: string): boolean =>
  fields
    .map(f => object[f])
    .join('◬')
    .toLowerCase()
    .includes(search.toLowerCase());

export const transformedIncludes = <T>(object: T, transform: (_: T) => string, search: string): boolean =>
  transform(object).toLowerCase().includes(search.toLowerCase());

export const isDefined = <T>(val: unknown): val is T => val !== undefined && val !== null;

export const isValueLessThanTwoChars = (value: string | NoValue): boolean => {
  return (value || '').length < 2;
};

export const isValueEqualToZeroX = (value: string | NoValue): boolean => {
  return zeroX.test(value || '');
};
