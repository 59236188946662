import { Directive, Input, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { TakeUntil } from '../take-until.component';
import { skip, Subject, takeUntil, tap } from 'rxjs';
import { addErrors } from './form.helper';
import { DirectiveManagementService } from './directive-management.service';

export type AltError = {
  regex: string | RegExp;
  errorKey: string;
};

type InternalAltError = AltError & {
  regex: RegExp;
};

const invalidCaractersErrorKey = 'priority.secondary.invalidCharacters';

@Directive({
  selector: '[folControlRegex]'
})
export class ControlRegexDirective extends TakeUntil implements OnInit {
  @Input() folControlRegex!: string | RegExp;
  private localRegExp!: RegExp;
  private valueBeforeChange!: string;
  private readonly showHelp = new Subject<string>();
  private readonly letters: string[] = [];
  private _altErrors: InternalAltError[] = [];
  @Input() set altErrors(altErrors: AltError[]) {
    this._altErrors = altErrors.map(({ regex, errorKey }) => ({ regex: this.toRegExp(regex), errorKey }));
  }

  constructor(
    private readonly ngControl: NgControl,
    private readonly directiveManagementService: DirectiveManagementService
  ) {
    super();
  }

  ngOnInit(): void {
    if (!this.folControlRegex) {
      throw new Error("You must set a value for 'folControlRegex' directive");
    }

    this.localRegExp = this.toRegExp(this.folControlRegex);

    this.valueBeforeChange = this.ngControl.control?.value;

    this.ngControl.control?.valueChanges.pipe(takeUntil(this.destroy)).subscribe(value => {
      if (typeof value === 'object') {
        return;
      }
      if ((value === 0 || value) && !this.localRegExp.test(value)) {
        this.directiveManagementService.addTreatment(this.ngControl.control, () => this.valueBeforeChange);
        this.showHelp.next(value[value.length - 1]);
        this.manageAlternativeErrors(value as string);
      } else {
        this.valueBeforeChange = value;
      }
    });

    this.showHelp
      .pipe(
        takeUntil(this.destroy),
        tap(letter => {
          if (!this.letters.includes(letter)) {
            this.letters.push(letter);
            if (this.letters.length > 5) {
              this.letters.shift();
            }
          }
        }),
        skip(5)
      )
      .subscribe(() => {
        if (this.ngControl.control) {
          addErrors(this.ngControl.control, { [invalidCaractersErrorKey]: { values: [...this.letters].sort() } });
          this.ngControl.control.markAsTouched();
        }
      });
  }

  private manageAlternativeErrors(value: string): void {
    this._altErrors.forEach(error => {
      if (value && error.regex.test(value) && this.ngControl.control) {
        addErrors(this.ngControl.control, { [error.errorKey]: true });
        this.ngControl.control.markAsTouched();
      }
    });
  }

  private toRegExp(regex: string | RegExp): RegExp {
    return typeof regex === 'string' ? new RegExp(regex.replace(/\//g, '')) : regex;
  }
}
