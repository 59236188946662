<div class='border' [ngClass]='{ active: active }'>
  <div class='wrapper number'>
    <span class='number'>{{ num }}.</span>
  </div>

  <div class='wrapper title'>
    <span class='title'>{{ title }}</span>
  </div>

  <div class='wrapper icon' *ngIf='validated'>
    <mat-icon>check_circle</mat-icon>
  </div>
</div>
