import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  LOCALE_ID,
  Optional,
  Self
} from '@angular/core';
import { TextInputComponent } from '../text-input/text-input.component';
import { INCOME_REGEX, INCOME_REGEX_INPUT } from '../../../../core/helpers/regexp.helper';
import { AbstractControl, FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { toValidator } from '../../../../core/helpers/form/form.helper';
import { formatNumber } from '@angular/common';

@Component({
  selector: 'fol-currency-input',
  templateUrl: './currency-input.component.html',
  styleUrls: ['../text-input/text-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CurrencyInputComponent extends TextInputComponent implements AfterContentInit {
  static thousandSeparator: string;
  @Input() inputId!: number;
  @Input() unitTranslationKey = 'application.personal-incomes.other.euro-per-month';

  controlRegex = INCOME_REGEX_INPUT;

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    public defaultErrorStateMatcher: ErrorStateMatcher,
    @Optional() public parentForm: NgForm,
    @Optional() public parentFormGroup: FormGroupDirective,
    cdr: ChangeDetectorRef,
    @Inject(LOCALE_ID) private readonly locale: string
  ) {
    super(ngControl, defaultErrorStateMatcher, parentForm, parentFormGroup, cdr, 'fol-income-input');
    if (CurrencyInputComponent.thousandSeparator) {
      CurrencyInputComponent.thousandSeparator = Intl.NumberFormat(this.locale)
        .format(11111)
        .replace(/\p{Number}/gu, '');
    }
  }

  ngAfterContentInit(): void {
    this.initControl();
    if (this.control) {
      this.control.addValidators([
        toValidator((control: AbstractControl) => !control.value || INCOME_REGEX.test(control.value), 'pattern')
      ]);
    }
    this.formatCurrency();
  }

  public formatCurrency(): void {
    const element = document.getElementById(this.id) as HTMLInputElement;
    if (element && this.control?.value) {
      element.value = formatNumber(this.control?.value, this.locale);
    }
  }

  reverseFormatCurrency(): void {
    const element = document.getElementById(this.id) as HTMLInputElement;
    if (element && this.control?.value) {
      element.value = String(
        parseFloat(
          String(this.control?.value).replace(new RegExp('\\' + CurrencyInputComponent.thousandSeparator, 'g'), '')
        )
      );
    }
  }
}
