import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

export type EnvironmentData = {
  production: boolean;
};

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {
  getEnvironment(): EnvironmentData {
    return environment;
  }
}
