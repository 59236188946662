import { DayMonthYear } from '../../../graphic/atom/form-fields/day-month-year-input/day-month-year-model';
import { KeyValue } from '../../core.model';
import { Locality } from '../birth-information/localities.service';
import { HolderCoHolder } from '../application.model';

export enum NationalityChoice {
  FRENCH = 'FRENCH',
  OTHER = 'OTHER'
}

export enum BirthCountryChoice {
  FRANCE = 'FRANCE',
  OTHER = 'OTHER'
}

export type BirthInformation = {
  [holderType in HolderCoHolder]: BirthInformationData;
};

export type BirthInformationData = {
  nationalityChoice: NationalityChoice;
  birthCountryChoice: BirthCountryChoice;
  birthDate: DayMonthYear;
  franceChosen?: FranceChosen;
  otherChosen?: OtherChosen;
  otherNationalityChosen?: OtherNationalityChosen;
};

export type FranceChosen = {
  birthDepartment: string | KeyValue;
  birthCity: string | Locality;
};

export type OtherChosen = {
  birthCountry: string;
  birthCity: string;
};

export type OtherNationalityChosen = {
  nationality: string;
};

export enum CountryChosen {
  NONE = 'NONE',
  FRANCE = 'FRANCE',
  OTHER = 'OTHER'
}

export enum NationalityChosen {
  NONE = 'NONE',
  FRANCE = 'FRANCE',
  OTHER = 'OTHER'
}
