import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, OnInit } from '@angular/core';
import { TakeUntil } from '../../core/helpers/take-until.component';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs';
import { ActivationStart, Router } from '@angular/router';

@Component({
  selector: 'fol-advancement',
  templateUrl: './advancement.component.html',
  styleUrls: ['./advancement.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdvancementComponent extends TakeUntil implements OnInit {
  @HostBinding('style.--fol-advancement-width') advancement = '0%';
  isAdvancementBarShown = false;

  constructor(private readonly route: Router, private readonly cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.route.events
      .pipe(
        filter(event => event instanceof ActivationStart),
        distinctUntilChanged(),
        takeUntil(this.destroy)
      )
      .subscribe(event => {
        if (event instanceof ActivationStart) {
          this.isAdvancementBarShown = !!event.snapshot.data['advancement'];
          this.advancement = event.snapshot.data['advancement'] + '%';
          this.cdr.markForCheck();
        }
      });
  }
}
