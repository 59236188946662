import { Injectable, InjectionToken } from '@angular/core';

export type Config = {
  url: string;
};

export const VERSION_TOKEN = new InjectionToken<string>('VERSION_TOKEN');

@Injectable()
export abstract class AppConfig {
  abstract baseWsURL: () => string;
}
