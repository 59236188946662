import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  TranslateModule,
  TranslateLoader,
  MissingTranslationHandler,
  MissingTranslationHandlerParams
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SharedModule } from '../shared/shared.module';
import { loadTranslations, TranslationInitPreload } from '../translate.config';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { StepperComponent } from './stepper/stepper.component';
import { GraphicModule } from '../graphic/graphic.module';
import { ApplicationModule } from './application/application.module';
import { UserModule } from './user/user.module';
import { DataTransmissionComponent } from './stepper/data-transmission/data-transmission.component';
import { InitComponent } from './init/init.component';

export const httpLoaderFactory = (http: HttpClient): TranslateHttpLoader =>
  new TranslateHttpLoader(http, './assets/i18n/', '.json');

export class MyMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams): string {
    console.error(`[Missing translation for key:  ${params.key}]`);
    return `[Missing translation for key:  ${params.key}]`;
  }
}

@NgModule({
  declarations: [StepperComponent, DataTransmissionComponent, InitComponent],
  imports: [
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient]
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MyMissingTranslationHandler
      },
      useDefaultLang: true,
      defaultLanguage: 'fr'
    }),
    GraphicModule,
    ApplicationModule,
    UserModule
  ],
  providers: [
    TranslationInitPreload,
    {
      provide: APP_INITIALIZER,
      useFactory: loadTranslations,
      deps: [TranslationInitPreload],
      multi: true
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' }
    }
  ],
  exports: [BrowserAnimationsModule, HttpClientModule]
})
export class CoreModule {}
