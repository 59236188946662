import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'fol-img',
  templateUrl: './img.component.html',
  styleUrls: ['./img.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImgComponent {
  @Input() src = '';
  @Input() title = '';
}
