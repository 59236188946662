import { AppRoutingModule } from './app-routing.module';
import { LOCALE_ID, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { registerLocaleData } from '@angular/common';
import LocaleEn from '@angular/common/locales/en';
import LocaleFr from '@angular/common/locales/fr';
import { GraphicModule } from './graphic/graphic.module';

// to use the local format like Date, Currency...
registerLocaleData(LocaleEn, 'en');
registerLocaleData(LocaleFr, 'fr');

@NgModule({
  declarations: [AppComponent],
  imports: [SharedModule, CoreModule, AppRoutingModule, GraphicModule],
  providers: [{ provide: LOCALE_ID, useValue: 'fr-FR' }],
  bootstrap: [AppComponent]
})
export class AppModule {}
