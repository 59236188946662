import { ChangeDetectionStrategy, Component, Input, NgZone } from '@angular/core';
import { ApplicationStore } from '../application/application-store.service';
import { ContractService } from '../application/contract.service';
import { Router } from '@angular/router';
import { TakeUntil } from '../helpers/take-until.component';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'fol-init',
  templateUrl: './init.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InitComponent extends TakeUntil {
  @Input() set initNumber(initNumber: string) {
    this.contractService
      .getContract(initNumber)
      .pipe(takeUntil(this.destroy))
      .subscribe(contractData => {
        this.applicationStore.reset(contractData);
        this.ngZone.run(() => this.router.navigateByUrl('/step'));
      });
  }

  constructor(
    private readonly contractService: ContractService,
    private readonly applicationStore: ApplicationStore,
    private readonly router: Router,
    private readonly ngZone: NgZone
  ) {
    super();
  }
}
