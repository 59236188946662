import { KeyValue } from '../../core.model';

export type SummaryModification = {
  duration?: string;
  annualKm?: string | KeyValue;
  financedAmount?: string;
};

export type Summary = {
  financedAmount: string;
  firstRent: string;
  nextRents: string;
  duration: Duration | string | null;
  purchaseOption: string;
  aggregateAmount: string;
  annualKm: string;
};

export enum Duration {
  TWENTY_FOUR = '24',
  THIRTY_SIX = '36',
  FORTY_EIGHT = '48',
  SIXTY = '60'
}

export enum AnnualKm {
  TEN_THOUSAND = '10000',
  FIFTEEN_THOUSAND = '15000',
  TWENTY_THOUSAND = '20000',
  TWENTY_FIVE_THOUSAND = '25000'
}
