import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormHeaderType } from './form-header.model';
import { ApplicationStore } from '../../../core/application/application-store.service';
import { map, Observable, startWith } from 'rxjs';
import { PersonalData } from '../../../core/application/personal-data-form/personal-data-form.model';
import { TranslateService } from '@ngx-translate/core';
import { toCamelCase, toClassName } from '../../../core/helpers/string.helper';

@Component({
  selector: 'fol-form-header',
  templateUrl: './form-header.component.html',
  styleUrls: ['./form-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormHeaderComponent implements OnInit {
  @Input() type!: keyof typeof FormHeaderType;
  @Input() label = '';
  @Output() headerClicked = new EventEmitter<void>();
  typeClass = '';
  localLabel: Observable<string> = this.translateService.onTranslationChange.pipe(
    startWith(''),
    map(() => {
      if (this.label) {
        return this.label;
      } else {
        switch (this.type) {
          case FormHeaderType.HOLDER:
          case FormHeaderType.CO_HOLDER: {
            return this.computeLabel(toCamelCase(this.type) as 'holder' | 'coHolder');
          }
        }
        return '';
      }
    })
  );

  constructor(
    private readonly applicationStore: ApplicationStore,
    private readonly translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.typeClass = toClassName(this.type);
  }

  private computeLabel(type: 'holder' | 'coHolder'): string {
    const { civility, firstName, lastName } = this.applicationStore.instant('identity')?.[type] as PersonalData;
    return [
      this.translateService.instant('helpers.form-header.civility.' + civility.toLowerCase()),
      firstName,
      lastName.toUpperCase()
    ].join(' ');
  }
}
