import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StepperComponent } from './core/stepper/stepper.component';
import { InitComponent } from './core/init/init.component';

const routes: Routes = [
  { path: '', redirectTo: 'step', pathMatch: 'full' },
  { path: 'step', component: StepperComponent },
  { path: 'init/:initNumber', component: InitComponent },
  {
    path: 'server-removal',
    loadChildren: () => import('./server-removal/server-removal-routing.module').then(m => m.ServerRemovalRoutingModule)
  },
  { path: 'user', loadChildren: () => import('./core/user/user-routing.module').then(m => m.UserRoutingModule) },
  {
    path: 'application',
    loadChildren: () => import('./core/application/application-routing.module').then(m => m.ApplicationRoutingModule)
  },
  { path: '**', redirectTo: '/', pathMatch: 'full' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      enableTracing: true,
      scrollPositionRestoration: 'enabled',
      bindToComponentInputs: true
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
