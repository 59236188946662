import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { TakeUntil } from '../take-until.component';

@Injectable({
  providedIn: 'root'
})
export class DirectiveManagementService extends TakeUntil {
  controlListManagement: Map<AbstractControl, Subject<(value: string) => string>> = new Map<
    AbstractControl,
    Subject<(value: string) => string>
  >();

  addTreatment(control: AbstractControl | null, directive: (value: string) => string): void {
    if (control) {
      let directivePipeline = this.controlListManagement.get(control);
      if (!directivePipeline) {
        directivePipeline = this.addNewControlToList(control);
        this.executeTreatment(directivePipeline, control);
      }
      directivePipeline.next(() => directive(control.value));
    }
  }

  addNewControlToList(control: AbstractControl): Subject<(value: string) => string> {
    const subject = new Subject<(value: string) => string>();
    this.controlListManagement.set(control, subject);
    return subject;
  }

  executeTreatment(treatment: Subject<(value: string) => string>, control: AbstractControl): void {
    treatment.pipe(takeUntil(this.destroy)).subscribe(func => {
      control.setValue(func(control.value), { emitEvent: false });
    });
  }
}
