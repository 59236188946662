import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { INITIAL_STEP, Step, WorkflowStep } from './stepper.model';

@Injectable({
  providedIn: 'root'
})
export class StepperService {
  localActiveStep = new BehaviorSubject<WorkflowStep>(INITIAL_STEP);
  get activeStep(): Observable<WorkflowStep> {
    return this.localActiveStep.asObservable();
  }

  setActiveStep(step: WorkflowStep): void {
    this.localActiveStep.next(step);
  }

  nextStep(): void {
    if (this.localActiveStep.value.nextStep) {
      this.setActiveStep(this.localActiveStep.value.nextStep);
    } else {
      throw new Error('Invalid Step state');
    }
  }

  getValidatedSteps(): Step[] {
    let lookForStep: WorkflowStep = INITIAL_STEP;
    const validated: Step[] = [];
    while (lookForStep.activeStep !== this.localActiveStep.value.activeStep && lookForStep.nextStep !== null) {
      validated.push(lookForStep.activeStep);
      lookForStep = lookForStep.nextStep;
    }
    return validated;
  }
}
