import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'fol-data-transmission',
  templateUrl: './data-transmission.component.html',
  styleUrls: ['./data-transmission.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataTransmissionComponent {
  @Input()
  title!: string;
}
