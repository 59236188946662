<ng-container *ngIf='control'>
  <div class='item' *ngIf="label">
    <label [ngClass]='{
      "mat-mdc-form-field-error": control.invalid && control.touched,
      "required": required
    }'>
      {{label}}
    </label>
  </div>
  <div class='item radio-select'>
    <mat-radio-group [formControl]="control">
      <mat-radio-button *ngFor='let yesNo of translatedYesOrNo; let i = index'
                        [ngClass]="{ 'radio-select--disabled': control.disabled }"
                        [value]='yesNo.value'>
        <span class="align-center-vertical">{{ yesNo.key }}</span>
      </mat-radio-button>
    </mat-radio-group>
    <ng-container *ngIf="control | getFirst as error">
      <mat-error *ngIf="control.invalid && control.touched">{{ error.key | translate: error.parameters }}</mat-error>
    </ng-container>
  </div>
</ng-container>
