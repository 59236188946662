import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DoCheck,
  Inject,
  InjectionToken,
  Input,
  OnDestroy,
  Optional,
  Self
} from '@angular/core';
import { ErrorStateMatcher, mixinErrorState } from '@angular/material/core';
import { AbstractMatFormField } from '../form-fields.model';
import { ControlValueAccessor, FormControl, FormGroupDirective, NgControl, NgForm, Validators } from '@angular/forms';
import { KeyValue } from '../../../../core/core.model';
import { Subject, Subscription } from 'rxjs';

export const HTML_SELECTOR = new InjectionToken<string>('HTML_SELECTOR');

@Component({
  selector: 'fol-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss', '../text-input/text-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectComponent
  extends mixinErrorState(AbstractMatFormField)
  implements ControlValueAccessor, AfterContentInit, DoCheck, OnDestroy
{
  static count = 0;
  @Input() label = '';
  @Input() placeholder = '';
  @Input() isProfessionSag = false;
  @Input() isRecap = false;
  mapValuesLocal: KeyValue[] = [];
  control: FormControl | null = null;
  onChange?: (value: KeyValue) => void;
  onTouched?: () => void;
  required = false;
  subscription: Subscription | undefined;
  id: string;
  @Input() set mapValues(mapValues: KeyValue[]) {
    this.mapValuesLocal = mapValues;
    if (this.isProfessionSag) {
      this.control?.setValue(this.mapValuesLocal.find(keyValue => keyValue.key === 'I'));
    } else if (this.mapValuesLocal.length === 1) {
      this.control?.setValue(mapValues[0]);
    } else {
      this.control?.reset();
    }
  }

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    public defaultErrorStateMatcher: ErrorStateMatcher,
    @Optional() public parentForm: NgForm,
    @Optional() public parentFormGroup: FormGroupDirective,
    private readonly cdr: ChangeDetectorRef,
    @Optional() @Inject(HTML_SELECTOR) protected htmlSelector: string
  ) {
    super(defaultErrorStateMatcher, parentForm, parentFormGroup, ngControl, new Subject<void>());
    this.id = (this.htmlSelector || 'fol-text-input') + ++SelectComponent.count;
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    } else {
      throw new Error("You must set a formControl for all 'fol-select'");
    }
  }

  ngDoCheck(): void {
    this.updateErrorState();
  }

  registerOnChange(fn: (_: KeyValue) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(obj: KeyValue): void {
    if (this.control?.value !== obj) {
      this.control?.setValue(obj);
    }
  }

  ngAfterContentInit(): void {
    if (this.ngControl.control) {
      this.control = this.ngControl.control as FormControl;
      this.required = this.control.hasValidator(Validators.required);

      this.subscription = this.control.statusChanges.subscribe(() => {
        this.cdr.markForCheck();
      });
    }
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
