import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { ImgComponent } from './atom/img/img.component';
import { SharedModule } from '../shared/shared.module';
import { TextInputComponent } from './atom/form-fields/text-input/text-input.component';
import { FormModule } from '../core/helpers/form/form.module';
import { StepComponent } from './atom/step/step.component';
import { RadioSelectComponent } from './atom/form-fields/radio-select/radio-select.component';
import { AdvancementComponent } from './advancement/advancement.component';
import { NumberInputComponent } from './atom/form-fields/number-input/number-input.component';
import { FormHeaderComponent } from './atom/form-header/form-header.component';
import { AutocompleteComponent } from './atom/form-fields/autocomplete/autocomplete.component';
import { AddressInputComponent } from './atom/form-fields/address-input/address-input.component';
import { MobilePhoneInputComponent } from './atom/form-fields/mobile-phone-input/mobile-phone-input.component';
import { LandlinePhoneInputComponent } from './atom/form-fields/landline-phone-input/landline-phone-input.component';
import { NameInputComponent } from './atom/form-fields/name-input/name-input.component';
import { ZipCodeAutocompleteComponent } from './atom/form-fields/zip-code-autocomplete/zip-code-autocomplete.component';
import { ZipCodeInputComponent } from './atom/form-fields/zip-code-input/zip-code-input.component';
import { MonthYearInputComponent } from './atom/form-fields/month-year-input/month-year-input.component';
import { DayMonthYearInputComponent } from './atom/form-fields/day-month-year-input/day-month-year-input.component';
import { SelectComponent } from './atom/form-fields/select/select.component';
import { ActivityTypeInputComponent } from './atom/form-fields/activity-type-input/activity-type-input.component';
import { CurrencyInputComponent } from './atom/form-fields/currency-input/currency-input.component';
import { IbanInputComponent } from './atom/form-fields/iban-input/iban-input.component';
import { BreadCrumbTrailModule } from './atom/breadcrumb-trail/breadcrumb-trail.component';
import { PhoneInputComponent } from './atom/form-fields/phone-input/phone-input.component';
import { YesNoInputComponent } from './atom/form-fields/yes-no-input/yes-no-input.component';

@NgModule({
  declarations: [
    HeaderComponent,
    ImgComponent,
    TextInputComponent,
    StepComponent,
    RadioSelectComponent,
    AdvancementComponent,
    NumberInputComponent,
    FormHeaderComponent,
    AutocompleteComponent,
    AddressInputComponent,
    MobilePhoneInputComponent,
    LandlinePhoneInputComponent,
    PhoneInputComponent,
    NameInputComponent,
    ZipCodeAutocompleteComponent,
    ZipCodeInputComponent,
    MonthYearInputComponent,
    DayMonthYearInputComponent,
    SelectComponent,
    ActivityTypeInputComponent,
    CurrencyInputComponent,
    IbanInputComponent,
    YesNoInputComponent
  ],
  exports: [
    HeaderComponent,
    TextInputComponent,
    StepComponent,
    RadioSelectComponent,
    AdvancementComponent,
    NumberInputComponent,
    FormHeaderComponent,
    AutocompleteComponent,
    AddressInputComponent,
    MobilePhoneInputComponent,
    NameInputComponent,
    ZipCodeAutocompleteComponent,
    ZipCodeInputComponent,
    MonthYearInputComponent,
    DayMonthYearInputComponent,
    SelectComponent,
    ActivityTypeInputComponent,
    LandlinePhoneInputComponent,
    PhoneInputComponent,
    CurrencyInputComponent,
    IbanInputComponent,
    YesNoInputComponent
  ],
  imports: [CommonModule, SharedModule, FormModule, BreadCrumbTrailModule]
})
export class GraphicModule {}
