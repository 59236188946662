import { MonthYear } from '../../../graphic/atom/form-fields/month-year-input/month-year-model';

export enum Housing {
  OWNER_WITH_LOAN = 'A',
  OWNER_NO_LOAN = 'P',
  TENANT = 'L',
  FAMILY_HOUSING = 'F',
  WORK_HOUSING = 'E',
  OTHER = 'D'
}

export type HousingData = {
  housing: Housing;
  since: MonthYear;
};
