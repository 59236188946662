<ng-container *ngIf='control'>
  <div class='item' *ngIf="label">
    <label [ngClass]='{
      "mat-mdc-form-field-error": control.invalid && control.touched,
      "required": required
    }' [for]="id">
      {{label}}
    </label>
  </div>
  <mat-form-field>
      <mat-select [formControl]="control"
                  [placeholder]="placeholder"
                  [id]="id">
        <div *ngIf="isRecap">
          <mat-option *ngFor="let item of mapValuesLocal" [value]="item.value">
            {{item.key}}
          </mat-option>
        </div>
        <div *ngIf="!isRecap">
          <mat-option *ngFor="let item of mapValuesLocal" [value]="item">
            {{item.value}}
          </mat-option>
        </div>
      </mat-select>
  </mat-form-field>
  <ng-container *ngIf="control | getFirst as error">
    <mat-error *ngIf="control.invalid && control.touched">{{ error.key | translate: error.parameters }}</mat-error>
  </ng-container>
</ng-container>
