export type DayMonthYear = {
  day: string;
  month: string;
  year: string;
};

export const isDayMonthYear = (x: unknown): x is DayMonthYear => {
  return (
    !!x &&
    typeof x === 'object' &&
    'day' in x &&
    'month' in x &&
    'year' in x &&
    Object.keys(x).length === 3 &&
    !!x.day &&
    !!x.month &&
    !!x.year
  );
};
