<div class="row center space-top-2"><h1>{{ 'application.summary.title' | translate }}</h1></div>
<div class="summary" *ngIf="summaryData$ | async as summaryData">
  <div class = "fields">
    <p>{{ "application.summary.summary-fields.first-rent" | translate }}</p>
    <p>{{ "application.summary.summary-fields.next-rents" | translate }}</p>
    <p>{{ "application.summary.summary-fields.financed-amount" | translate }}</p>
    <p>{{ "application.summary.summary-fields.duration" | translate }}</p>
    <p>{{ "application.summary.summary-fields.purchase-option" | translate }}</p>
    <p>{{ "application.summary.summary-fields.aggregate-amount" | translate }}</p>
    <p>{{ "application.summary.summary-fields.annualKm" | translate }}</p>
  </div>
  <div class = "values">
    <p>{{ summaryData.firstRent | formatNumber }}{{ spacing }}€</p>
    <p>{{ summaryData.nextRents | formatNumber }}{{ spacing }}€</p>
    <p>{{ summaryData.financedAmount | formatNumber }}{{ spacing }}€</p>
    <p>{{ summaryData.duration }}{{ spacing }}{{ 'application.summary.summary-fields.month' | translate }}</p>
    <p>{{ summaryData.purchaseOption | formatNumber }}{{ spacing }}€</p>
    <p>{{ summaryData.nextRents | formatNumber }}{{ spacing }}€</p>
    <p>{{ summaryData.annualKm | formatNumber }}{{ spacing }}km</p>
  </div>
</div>
  <a mat-flat-button color="primary" *ngIf="!isFormShowed" (click)="toggleForm()" id="modify-button" class="modify-button">
    {{"application.summary.buttons.modify" | translate}}
  </a>
<form *ngIf="isFormShowed" [formGroup]="summaryForm" class="space-top-1">
  <fol-radio-select formControlName='duration'
                    [label]='"application.summary.form-fields.duration" | translate'
                    [mapValues]='translatedDatesMap$ | async' id="field1"/>
  <fol-select formControlName="annualKm" [mapValues]="annualKmSelection"
              [label]='"application.summary.form-fields.annual-km" | translate'
              id="field2"/>
  <fol-currency-input formControlName="financedAmount"
                    unitTranslationKey="application.summary.euro"
                    [label]='"application.summary.form-fields.financed-amount" | translate'
                    id="field3"/>
</form>

<div class="row center text-center space-top-2">{{ 'application.summary.subtitle' | translate }}</div>
<div class="card">
  <div class="title">
    <p>{{ "application.summary.offer.your-offer" | translate }}</p>
  </div>
  <div class="content">
    <p class="offer">{{ "application.summary.offer.loa" | translate }} <strong>{{ "application.summary.offer.full" | translate }}</strong></p>
    <p class="hundred-percent">{{ "application.summary.offer.hundred-percent" | translate }}</p>
    <div class="price">
      <p>383,83{{ "application.summary.offer.euros-per-month" | translate }}</p>
    </div>
    <ul class="options">
      <li>
        <mat-icon color="primary" svgIcon='fol-check'></mat-icon><p>{{ "application.summary.offer.extended-warranty" | translate }}</p>
      </li>
      <li>
        <mat-icon color="primary" svgIcon='fol-check'></mat-icon><p>{{ "application.summary.offer.borrower-insurance" | translate }}</p>
      </li>
      <li>
        <mat-icon color="primary" svgIcon='fol-check'></mat-icon><p>{{ "application.summary.offer.assistance" | translate }}</p>
      </li>
      <li>
        <mat-icon color="primary" svgIcon='fol-check'></mat-icon><p>{{ "application.summary.offer.purchase-value-warranty" | translate }}</p>
      </li>
    </ul>
    <a mat-flat-button color="primary" [routerLink]="['../user-info-modification']" id="confirmButtom" class="modify-button">
      {{ "application.summary.buttons.confirm" | translate }}
    </a>
  </div>
</div>
<fol-legal-terms />
<ng-template #stickyButtonWrapper>
  <div class="justify-sticky-buttons-center">
    <a mat-flat-button color="secondary" [routerLink]="['../bank-details']" id="backButton">
      <mat-icon matPrefix>keyboard_arrow_left</mat-icon>
      {{"application.personal-data.buttons.back" | translate}}
    </a>
  </div>
</ng-template>

